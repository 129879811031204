@media (max-width:1500px) {
    .container { width: 1200px; }
    .my-account ul li a { padding: 0 10px; }
    .main-header { padding: 15px 0; }
    .navbar-form .input-group { width: 250px; }
    .navigation ul li { padding: 0; }
    .banner-section { padding: 40px 0; }
    .bx-wrapper .bx-pager { bottom: 45px; }

	.banner-right { padding: 20px 20px 0px; color: #FFF; font-size: 14px; line-height: 18px; }
	.banner-list-content { padding-top: 15px; }
	.banner-list-content h3 { font-size: 20px; margin: 0 0 5px; }


    .home-categories .row { padding: 20px 0 20px; }
	.categories h2 { font-size: 26px; padding: 0 25px;}

    .intro-section { padding: 70px 0; }
    .productlist-section { padding: 30px 0 0; }
    .product-detail { padding: 50px 0; }
    .product-info { width: 60%; }
    .product-cart { padding: 35px 35px 20px 35px; }

	.navigation ul li.open > ul.sub-menu, .navigation ul li:hover > ul.sub-menu { padding: 28px 0 5px 0; }
	.woocommerce div.product .product-left form.cart { padding-left: 0; }
	.woocommerce div.product .product-left form.cart div.quantity input { width: 180px; }

}

@media (max-width:1279px) {
    .container { width: 960px; }

	h2 { font-size: 26px; }
	h3 { font-size: 22px; }
	h4,.product-list h4 { font-size: 18px; }
    .header-top { padding: 10px 0; font-size: 16px; }
	.navigation ul { padding: 0; }
    .navigation ul li a { padding: 10px; font-size: 16px; }
    .navbar-form .input-group { width: 200px; }
    .search-form input.form-control, .search-form .input-group .input-group-btn .btn { height: 35px; line-height: 35px;}
    .search-form .input-group .input-group-btn .btn { padding: 0 10px 0 0; }
    .input-group .input-group-btn .btn .fa { font-size: 16px; }
    .banner-section { padding: 30px 0; }

	.banner-section .eq-height { height: auto !important; float: left; width: 100%; }
	.banner-content { max-width: 400px; font-size: 16px; line-height: 24px; left: 30px; }
	.bx-wrapper .bx-pager { bottom: 40px; left: 27px; }

    .banner-right { margin-top: 25px; padding: 30px; }
	.banner-list-content { padding-top: 15px; }
    .banner-right .banner-list-content:first-child { padding-top: 0; }

	.categories h2 { font-size: 22px;  padding: 0 15px;  }

	.intro-section { padding: 50px 0; }
	.intro-title { font-size: 24px; line-height: 28px; margin-bottom: 15px;}

    .contact-form p { width: 240px; }
    .contact-form input { height: 40px; }
    .contact-form input[type="submit"] { line-height: normal; }
    .footer-links ul li a,.footer-bottom { font-size: 14px; line-height: 20px; }

    .single-image { background-size: cover; margin-bottom: 0 }
    .product-right { padding-left: 20px; }
    .product-info ul { margin: 15px 0; }
    .product-info ul li { padding-left: 0; padding-bottom: 5px; }
    .contact-info { padding: 20px; }


	.navigation ul li.open > ul.sub-menu, .navigation ul li:hover > ul.sub-menu { padding: 23px 0 5px 0; width: 250px; }
	.navigation ul.sub-menu li a { padding: 5px 10px !important; font-size: 16px; line-height: 20px; }

	.woocommerce div.product .product-left form.cart div.quantity input { width: 100px; font-size: 14px; }
	.woocommerce div.product .product-left form.cart .button {  padding: 0 10px; }
}

@media (max-width:991px) {
    .container { width: 740px; }
    .header-top { font-size: 16px; line-height: 24px; }
    .my-account { padding-left: 0; }
    .my-account ul li a { padding: 0 5px; }
    .my-account ul li:first-child a { padding-left: 0;}
    .my-account ul li a span { padding-right: 10px; }

    .align-middle { display: block; text-align: center; }
    .logo { margin: 10px 0; }
    .navigation ul li:first-child a { padding-left: 0; }

	.banner-title { font-size: 24px; line-height: 30px; margin-bottom: 10px; }

    .contact-form p { width: 170px; }
    .contact-form input { padding-left: 10px; }
    .contact-form input[type="submit"] { width: 125px; }

    .footer-bottom,.footer-bottom .footer-links { text-align: center; }
	.form-title { font-size: 18px; }
    .footer-bottom p { padding: 15px 0 0; }
    .footer-links ul li a { padding: 10px; }

    .product-list { padding: 40px 0 20px; }
    .single-image {  background-size: contain; }
    .product-cart {  max-width: 100%; }
    .product-right { padding-left: 0; margin-top: 15px; }

	.navigation ul li.open > ul.sub-menu, .navigation ul li:hover > ul.sub-menu { padding: 22px 0 5px 0; }
	.product-detail .col-sm-5,.product-detail .col-sm-7 { width: 100% !important; float: left !important; }
	.woocommerce div.product .product-left form.cart { padding-left: 60px; margin-bottom: 20px; }
	.woocommerce div.product .product-left form.cart div.quantity input { width: 300px; }
	.woocommerce div.product .product-left form.cart .button { padding: 0 25px; }

	.product-left { max-width: 100%; }

}

@media (max-width:767px) {
    .container { width: 100%; padding-left: 20px; padding-right: 20px; }
	body { background: #FFF; font-size: 16px; line-height: 26px; }

	.input-group input::-moz-placeholder { color: #E9F100; }
	.input-group input::-webkit-input-placeholder { color: #E9F100; }
	.input-group input::-ms-input-placeholder { color: #E9F100; }

	h2,.category-title { font-size: 25px; }
	.row { margin-left: -20px; margin-right: -20px; }
	.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 { padding-left: 20px; padding-right: 20px; }
    .header-top { padding: 6px 0; position: relative; background-image: linear-gradient(-100deg, #2C4690 0%, #2D5AA2 50%); }
	.header-call { font-size: 16px;  margin-bottom: 10px; padding: 0 15px; }
    .search-form  { float: none; margin-left: -10px; }
    .search-form .input-group .input-group-btn .btn { padding: 0 10px; }
    .input-group .input-group-btn .btn .fa { font-size: 23px; color: #E9F100; }
    .navbar-form .input-group {  width: 230px; border: 0px; }
    .navbar-form .input-group { border: 1px solid transparent; }
    .navbar-form .input-group:focus,.navbar-form .input-group:hover { border: 1px solid #E9F100; outline: 0px none; }
    .search-form input.form-control, .search-form .input-group .input-group-btn .btn { height: 30px; }
    .search-form input.form-control { padding-left: 0; padding-right: 10px; color: #E9F100; }

    .mobile-icon { position: absolute; top: 0; right: 0; bottom: 0; width: 60px; text-align: center; background: #2c4690; }
    .mobile-icon a { font-size: 25px; color: #E9F100; padding: 10px; display: inline-block; }

	.header-right { position: fixed; right: -250px; top: 0; height: 100%; width: 250px;  -webkit-transition: all 0.3s ease-in-out 0s; transition: all 0.3s ease-in-out 0s; z-index: 19; background-image: linear-gradient(-90deg, #2C4690 5%, #2D5AA2 97%); text-align: center; overflow: auto; padding: 30px 0; margin: 0; overflow-x: hidden; -webkit-box-shadow: inset 0px 4px 6px 5px rgba(44,75,148,0.33); box-shadow: inset 0px 4px 6px 5px rgba(44,75,148,0.33); }
	.open-menu .header-right { right: 0; }
	.site { -webkit-transition: all 0.3s ease-in-out 0s; transition: all 0.3s ease-in-out 0s; position: relative; left: 0; }
	.open-menu .site { left: -250px; }
	.align-right { display: none; }
	.header-shipping { color: #FFF; padding: 0 15px; font-size: 16px; line-height: 24px; }
	.my-account { padding: 10px 15px; }
	.my-account ul li a { font-size: 16px; line-height: 28px; }
	.navigation { display: block; text-align: left; }
	.navigation ul li { display: block; }
	.navigation ul li a,.navigation ul li:first-child a,.navigation ul li:last-child a { display: inline-block; font-size: 16px; line-height: 22px; padding: 10px 15px; }
	.navigation ul li a { color: #FFF; }
	.open-menu .mobile-icon a .fa:before{ content: "\f00d"; }

	.main-header { -webkit-box-shadow: none; box-shadow: none; }
	.main-header { padding: 10px 0; }
	.home .main-header { padding-bottom: 0; }
	.banner-section { padding: 0; background-image: linear-gradient(90deg, #2C4690 5%, #2D5AA2 97%); }
	.banner-right { margin-top: 0; background: transparent; padding: 25px 30px 30px;}
	.banner-list-content h3 { font-size: 25px; margin: 0; }
	.banner-section .eq-height { padding: 0; text-align: center;}
	.bx-wrapper { -webkit-box-shadow: none; box-shadow: none; }
	.bx-wrapper .bx-viewport { background: transparent; }
	ul.bxslider li { background-image: none !important; }
	ul.bxslider li img { opacity: 1; width: 100%; }
	.bx-wrapper .bx-pager { display: none !important}
	.banner-content { position: relative; top: 0; left: 0; color: #FFF; max-width: 100%;  -webkit-transform: translateY(0%); transform: translateY(0%); font-size: 14px; line-height: 17px; padding: 28px 20px 10px; }
	.banner-content .banner-title { font-size: 23px; margin-bottom: 8px; }
	.banner-content p { margin-bottom: 0; }
	.banner-list-content { padding-top: 10px; }

	.home-categories { padding: 35px 0; text-align: center; }
	.home-categories h2:after,.category-title:after { content: none; }
	.home-categories .row { padding: 10px 0; }
	.categories { margin-bottom: 10px; }
	.categories h2 { font-size: 20px; padding: 0 45px; }

	.intro-section { padding: 10px 0 70px; }
	.intro-title { font-size: 22px; line-height: 30px; margin-bottom: 20px; }

	.footer-top { padding: 33px 0 20px; }
	.form-title { font-size: 22px; margin-bottom: 20px; }
	.contact-form p { width: 100%; margin: 0 0 12px; }
	.contact-form input { height: 47px; padding-left: 15px; }
	.contact-form p:nth-child(5) {	width: 100%; text-align: center; }
	.contact-form input[type="submit"] { width: 135px; }

    .site-footer .contact-form .nf-form-content nf-fields-wrap { display: block; }
    .site-footer .contact-form .nf-form-content input[type="button"] { display: block; margin: 0 auto; }
    .site-footer .contact-form .nf-form-content nf-field { padding-right: 0; }

	.footer-bottom { margin: 0 -15px; }
	.footer-bottom p { padding: 25px 0 0; }
	.footer-bottom .footer-links { padding: 20px 0; }
	.footer-links ul li a { padding: 2px 12px; font-size: 16px; }


	.breadcumb { display: none; }
	.align-middle { padding-bottom: 10px; background: #FFFFFF; -webkit-box-shadow: 0 4px 6px 0 rgba(44,75,148,0.33); box-shadow: 0 4px 6px 0 rgba(44,75,148,0.33); }
	.productlist-section { padding: 20px 0 0; }
	.term-description { padding-top: 0; line-height: 24px; }
	.product-list { padding: 20px 0 40px; }
	.product-list h3, .product-list h4 { line-height: 22px; }
	.product-list h3 { font-size: 20px; }
	.product-list .product-subtitle { font-size: 16px; line-height: 20px; margin-bottom: 30px; }

	.product-left .visible-xs { text-align: center; line-height: 27px; }
	.product-single-name { font-size: 26px; }
	.ref-no { font-size: 20px; }
	.product-left .contact-info { display: none; }
	.title-mobile,.single-image { padding: 0 20px; }
	.product-detail .col-md-4 { padding: 0; }
	.product-cart { border-radius: 0; padding: 20px; width: 100%; }
	.product-cart p { display: none; }
	.product-right .product-single-name,.product-right .ref-no { display: none; }
	.product-info-list { padding: 15px 0 30px; }
	.product-info { width: 100%; margin-top: 20px; }
	.signature-image { float: left; width: 100%; text-align: center; }
	.signature-image img { height: auto; }

	.bottom-info { background: #4A4A4A; color: #FFF; padding: 20px; }
	.contact-info { font-size: 18px; padding: 30px 20px; }
	.contact-info a { word-break: break-word;  white-space: normal; }
	.site-content { margin-bottom: 0 !important; }
	.site-footer { position: relative; }

	.error-404 { padding: 50px 0; }

	.navigation ul li.open > ul.sub-menu, .navigation ul li:hover > ul.sub-menu { padding: 0; width: 100%; position: relative; background: transparent; }
	.navigation ul li ul.sub-menu:before { content: none; }
	.navigation ul.sub-menu li a { padding: 7px 30px !important; }

	.product-detail { padding: 15px 0; }
	.product-detail .col-sm-5, .product-detail .col-sm-7 { padding: 0; }
	.woocommerce div.product .product-left form.cart { padding-left: 0; text-align: center }
	.product-prise { font-size: 20px; }
	div.qty,.woocommerce div.product form.cart div.quantity { float: none; display: inline-block; margin: 0; padding-left: 0; }
	.product-info ul { margin: 0; }
	.product-info-list { padding: 0; }
	.signature-image { margin: 30px 0; }
	.product-info ul li { padding-bottom: 10px; line-height: 20px; }
	.woocommerce div.product .product-left form.cart div.quantity input { width: 80px; }
	.woocommerce div.product .product-left form.cart .button { padding: 0 20px; float: none; display: inline-block; margin-left: -5px; margin-top: -1px; }
	.woocommerce-product-gallery { padding: 0 20px; margin-top: 20px; }
	.product-right { padding: 0 20px; }

	.navigation ul li.menu-item-has-children > a:after { content: "\f107";     font: normal normal normal 14px/1 FontAwesome; display: inline-block; margin-left: 15px; font-size: 20px; position: relative; top: 2px; }
	.woocommerce span.onsale { left: 20px; top: 0; }

	span.wpcf7-not-valid-tip,div.wpcf7-validation-errors {  position: relative; top: 0; bottom: 0; padding: 10px 0 0 0;}


}
