/*-------------------- THEME CSS ---------------------*/



*,
*:before,
*:after {  box-sizing: inherit; }
html {font-family: sans-serif;-webkit-text-size-adjust: 100%;-ms-text-size-adjust: 100%; box-sizing: border-box; position: relative; min-height: 100%; }
body {	margin: 0;  }
ul,ol { margin: 0 0 20px; padding: 0 0 0 20px; }
ul { list-style: disc; }
ol { list-style: decimal; }
li > ul, li > ol { 	margin-bottom: 0; margin-left: 1.5em; }
img { height: auto; max-width: 100%; }
a { color: #2C4690; font-weight: 700; }
a:hover,a:focus,a:active { text-decoration: none; outline: 0px none; }
.alignleft { display: inline; float: left; margin-right: 1.5em; }
.alignright { display: inline; float: right; margin-left: 1.5em; }
.aligncenter { clear: both; display: block; margin-left: auto; margin-right: auto; }
.clear:before,
.clear:after,
.entry-content:before,
.entry-content:after,
.comment-content:before,
.comment-content:after,
.site-header:before,
.site-header:after,
.site-content:before,
.site-content:after,
.site-footer:before,
.site-footer:after {
	content: "";
	display: table;
	table-layout: fixed;
}

.clear:after,
.entry-content:after,
.comment-content:after,
.site-header:after,
.site-content:after,
.site-footer:after {
	clear: both;
}

body { background: #eaf0f8; font-family: museo-sans, sans-serif; font-style: normal; font-weight: 300; font-size: 18px; color: #4A4A4A; line-height: 30px; }

h1,h2,h3,h4,h5,h6 { font-weight: 700; color: #2C4690; font-family: museo-sans-condensed, sans-serif; }

ul.list-none { margin: 0; padding: 0; list-style: none; }
ul.list-none li { display: inline-block; }
.align-middle { display: -webkit-flex; display: -moz-flex; display: -ms-flexbox; display: -ms-flex; display: flex; -webkit-align-items: center; -moz-align-items: center; -ms-align-items: center; -ms-flex-align: center; align-items: center; }
.align-right { display: -webkit-flex; display: -moz-flex; display: -ms-flexbox; display: -ms-flex; display: flex; -webkit-align-items: center; -moz-align-items: center; -ms-align-items: center; -ms-flex-align: center; align-items: center; -webkit-justify-content: space-between; -moz-justify-content: space-between; -ms-justify-content: space-between; justify-content: space-between; }
.cover { background-size: cover; background-repeat: no-repeat; background-position: center center; }
.cover img { opacity: 0; width: 100%; }

input[type="number"],input { -webkit-appearance: none; -webkit-box-shadow: none !important; box-shadow: none !important; }
input:hover,input:focus { background: none; border: 0px; -webkit-box-shadow: none !important; box-shadow: none !important; outline: 0px none; }

.content-area { background: #FFF; padding: 30px 0;  margin: 40px 0 0; }

.input-group input::-moz-placeholder { font-family: museo-sans-condensed, sans-serif; font-weight: 300; color: #4A4A4A; font-style: italic; font-size: 20px; }
.input-group input::-webkit-input-placeholder { font-family: museo-sans-condensed, sans-serif; font-weight: 300; color: #4A4A4A; font-style: italic; font-size: 20px; }
.input-group input::-ms-input-placeholder { font-family: museo-sans-condensed, sans-serif; font-weight: 300; color: #4A4A4A; font-style: italic; font-size: 20px; }
.input-group input:-ms-input-placeholder { font-family: museo-sans-condensed, sans-serif; font-weight: 300; color: #4A4A4A; font-style: italic; font-size: 20px; }

.contact-form input::-moz-placeholder { color: #FFF; font-style: normal; font-size: 20px; font-family: museo-sans-condensed, sans-serif; }
.contact-form input::-webkit-input-placeholder { color: #FFF; font-style: normal; font-size: 20px; font-family: museo-sans-condensed, sans-serif; }
.contact-form input::-ms-input-placeholder { color: #FFF; font-style: normal; font-size: 20px; font-family: museo-sans-condensed, sans-serif; }
.contact-form input:-ms-input-placeholder { color: #FFF; font-style: normal; font-size: 20px; font-family: museo-sans-condensed, sans-serif; }

a, button, input { transition: color 0.15s, background 0.15s; }


/*---------------- HEADER CSS ----------------*/
.header-top { background-image: linear-gradient(-90deg, #2C4690 30%, #2D5AA2 70%); color: #E9F100; padding: 7px 0; font-size: 20px; }
.header-call { font-weight: 700; font-family: museo-sans-condensed, sans-serif; }
.header-call a { color: #E9F100; }
.header-shipping { color: #FFF; font-family: museo-sans-condensed, sans-serif; font-weight: 300; }
.my-account ul li a { color: #E9F100; padding: 0 22px; font-family: museo-sans-condensed, sans-serif; }
.my-account ul li a span { padding-right: 20px; }

.main-header { background: #FFFFFF; -webkit-box-shadow: 0 4px 6px 0 rgba(44,75,148,0.33); box-shadow: 0 4px 6px 0 rgba(44,75,148,0.33); padding: 25px 0; }

.navigation,.search-form { display: inline-block; vertical-align: middle; }
.search-form { float: right; }
.navigation ul { margin: 0; padding: 0 0 0 20px; list-style: none }
.navigation ul li { display: inline-block; padding: 0 6px; position: relative; }
.navigation ul li a { padding: 12px; display: block; font-size: 20px; color: #2C4690; font-weight: 700; height: 100%; text-transform: capitalize; width: 100%; font-family: museo-sans-condensed, sans-serif; }
.navigation ul li > a:hover,.navigation ul li.current-menu-ancestor > a,.navigation ul li.current-menu-item > a { color: #000000; }


.navigation ul li ul.sub-menu { display: block; transition: opacity 0.15s, left 0s 0.15s;  opacity: 0; position: absolute; top: 100%; left: -999em; width: 350px; background-color: #ffffff; padding: 44px 0 5px 0; text-align: left; margin-top: -7px; z-index: 2;}
.navigation ul li.open > ul.sub-menu,.navigation ul li:hover > ul.sub-menu { opacity: 1; left: 0; transition-delay: 0.15s; }
.navigation ul li:last-child.open > ul.sub-menu,.navigation ul li:last-child:hover > ul.sub-menu { left: auto; right: 0; }
.navigation ul li ul.sub-menu:before { content: ""; width: 100%; height: 3px; background: #ef4c27; display: block; margin: 0 0 3px 0; }
ul.sub-menu li { display: block; padding: 0 5px;}
ul.sub-menu li a { padding: 5px 20px; }




.navbar-form { margin: 0; padding: 0; -webkit-box-shadow: none !important; box-shadow: none !important; }
.navbar-form .input-group { border: 2px solid #979797; border-radius: 100px; width: 365px; }
.search-form input.form-control,.search-form .input-group .input-group-btn .btn { border: 0px; border-radius: 0px; background: transparent; height: 46px; padding: 0 20px; color: #4A4A4A; line-height: 46px; font-style: italic; }
.search-form .input-group .input-group-btn { width: 1%; }
.input-group .input-group-btn .btn .fa { font-size: 20px; color: #2C4690;}

.home .site-content { background: transparent; }

/*----------------------------- BANNER CSS ------------*/
.banner-section { padding: 50px 0 55px; }
.bx-wrapper { margin: 0; -webkit-box-shadow: 0 4px 6px 0 rgba(44,75,148,0.33);  box-shadow: 0 4px 6px 0 rgba(44,75,148,0.33);}
.bx-wrapper .bx-viewport { box-shadow: none;  border: 0px;  left: 0; }
ul.bxslider { margin: 0; padding: 0; list-style: none; }
.bx-wrapper .bx-pager { bottom: 62px; left: 43px; width: auto; padding: 0; line-height: initial; }
.bx-wrapper .bx-pager.bx-default-pager a { width: 15px; height: 15px; border: 1px solid #FFF; border-radius: 100px; background: transparent; }
.bx-wrapper .bx-pager.bx-default-pager a:hover, .bx-wrapper .bx-pager.bx-default-pager a.active { background: #e9f100; }

.banner-content { position: absolute; top: 50%; left: 48px; color: #FFF; max-width: 480px; -webkit-transform: translateY(-50%); transform: translateY(-50%); font-size: 20px; line-height: 24px; }
.banner-title { font-size: 30px; line-height: 36px; margin-bottom: 20px; font-weight: 700; font-family: museo-sans-condensed, sans-serif; }

.banner-right { background: #2c4791; height: 100%; padding: 40px 30px 0px; color: #FFF; font-size: 16px; line-height: 20px; }
.banner-right .banner-list-content:first-child { padding-top: 10px; }
.banner-list-content { padding-top: 33px; }
.banner-list-content h3 { font-size: 25px; color: #E9F100; font-weight: 700; margin: 0; }
.banner-list-content a { font-weight: 700; color: #FFF; }

/*------------------ CATEGORIES CSS ------------*/

.home-categories { overflow-x: hidden; }
.home-categories h2 { color: #2C4690; font-weight: 700; position: relative; margin: 0 0 10px; }
.home-categories h2:after { content: ""; display: inline-block; height: 1px; width: 100%; background: #6C84C7; position: absolute; margin-left: 18px; top: 12px; }
.home-categories .row { padding: 20px 0 40px; }

.categories { margin-bottom: 24px; -webkit-box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50); box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50); overflow: hidden; }
.categories a { display: block; width: 100%; height: 100%; position: relative; -webkit-transition: all 0.5s ease; transition: all 0.5s ease; -webkit-transform: scale(1); transform: scale(1); background-size: cover; background-position: center center; background-repeat: no-repeat; }
.categories a img { opacity: 0; }
.categories a:hover { -webkit-transform: scale(1.1); transform: scale(1.1); }
.categories a:before { content: ""; position: absolute; top: 0; left: 0; right: 0; bottom: 0; width: 100%; height: 100%; background: rgba(0,0,0,0.5); }
.categories h2 { margin: 0; position: absolute; top: 50%; left: 50%; width: 100%; text-align: center; transform: translate(-50%,-50%); color: #FFF; font-weight: 700; padding: 0 45px; }
.categories h2:after { content: none; }

/*----------------- INTRO CSS ---------------*/

.intro-section { background: #FFF; padding: 85px 0; text-align: center; }
.intro { max-width: 980px; margin: 0 auto; }
.intro-title { font-size: 27px; color: #2C4690; font-weight: 700; margin-bottom: 20px; font-family: museo-sans-condensed, sans-serif; }
.intro p { margin-bottom: 0; }
.intro a { color: #000; font-weight: 700; }

/*--------------------- FOOTER CSS -------------*/

.site-footer { background-image: linear-gradient(90deg, #2C4690 27%, #2D5AA2 74%); color: #FFF; bottom: 0; left: 0; right: 0; }
.footer-top { padding: 45px 0 70px; max-width: 1045px; margin: 0 auto; position: relative; }
.form-title { text-align: center; font-size: 22px; color: #ECF2F9; font-weight: 700; margin-bottom: 16px; font-family: museo-sans-condensed, sans-serif; }
.contact-form p { display: block; float: left; width: 275px; margin: 0 20px 0 0; }
.contact-form p:nth-child(5) { width: auto; margin-right: 0; }
.contact-form input { background: transparent; margin: 0; color: #FFF; border: 2px solid #ECF2F9; border-radius: 12px; width: 100%; padding: 0; padding-left: 22px; height: 48px;}
.contact-form input[type="submit"] { padding: 0; width: 135px; text-align: center; line-height: 40px; background: #e9f100; border: 2px solid #e9f100; color: #2C4690; font-weight: 700; border-radius: 100px; font-family: museo-sans-condensed, sans-serif; font-size: 20px; }
span.wpcf7-not-valid-tip,div.wpcf7-validation-errors,div.wpcf7-mail-sent-ng,div.wpcf7-mail-sent-ok { border: 0px; float: left; margin: 0; padding: 10px 0 0 0; width: 100%;  text-align: center; color: #FFF; font-size: 16px; }
span.wpcf7-not-valid-tip { position: absolute; top: 35px; margin: 0; padding: 0; }
div.wpcf7-validation-errors { position: absolute; bottom: 5px; margin: 0; padding: 0; }
div.wpcf7-mail-sent-ok { color: #e9f100; }

.footer-bottom { font-size: 16px; color: #6C84C7; position: relative; border-top: 1px solid #6C84C7; }
.footer-bottom .copyright,.footer-bottom .footer-links { padding: 0 ;}
.footer-bottom a { color: #6C84C7; }
.footer-bottom a:hover { color: #FFF; }
.footer-bottom p { margin: 0; padding: 12px 0; }
.footer-links ul { margin: 0; padding: 0; list-style: none; }
.footer-links ul li { display: inline-block; }
.footer-links ul li a { font-size: 16px; color: #6C84C7; padding: 12px 10px ; display: inline-block; font-weight: 300; }
.footer-links ul li.current-menu-item > a ,.footer-links ul li a:hover { color: #FFF; }
.footer-links ul li:last-child a { padding-right: 0; }

/*---------------- PRODUCT LIST PAGE ----------------*/

.breadcumb ul li { font-size: 16px; padding: 10px 5px; display: inline-block; line-height: 26px; color: #0F3C70; font-family: museo-sans-condensed, sans-serif; font-weight: 300; }
.breadcumb ul li a {  color: #0F3C70; position: relative; display: inline-block; font-weight: 300; }
.breadcumb ul li a:after { content: "\f105"; position: absolute; top: 8px; right: -12px; font: normal normal normal 14px/1 FontAwesome; font-size: 12px; text-rendering: auto; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;}
.breadcumb ul li:last-child a:after { content: none; }

.productlist-section { background: #FFF; padding: 40px 0; }
.category-title { position: relative; overflow-x: hidden; margin-bottom: 20px; }
.category-title:after { content: ""; display: inline-block; height: 1px; width: 100%; background: #dce2f2; position: absolute; margin-left: 15px; top: 15px; }
.term-description { padding-top: 10px; }

.product-list,.product-detail { padding: 60px 0 0; }
.product-detail .col-sm-5 { width: 41.66666667% !important; }
.product-left { max-width: 465px; margin: 0 auto; }
.product-left .col-sm-12 { width: 100% !important;}
.woocommerce div.product div.images { margin-bottom: 25px; }
.woocommerce div.product div.images .flex-control-thumbs { text-align: center; margin: 10px 0 0 0; border-radius: 8px; background-image: linear-gradient(90deg, #2C4690 5%, #2D5AA2 97%); padding: 0 5px; }
.woocommerce div.product div.images .flex-control-thumbs li { float: none; display: inline-block; padding: 15px 5px 5px; }
.product-detail .col-sm-7 { width: 58.33333333% !important; overflow-x: hidden; }
.woocommerce div.product div.images img { max-width: 100%;  height: auto; width: auto !important; margin: 0 auto; }

.product-list .product-image { margin-bottom: 15px; }
.product-list h3 { text-transform: uppercase; }
.product-list h3,.product-list h4 { line-height: 27px; margin: 0; }
.product-list h4 { color: #4A4A4A; font-size: 20px; }
.product-list .product-subtitle { margin-bottom: 50px; }

.product-cart {  background-image: linear-gradient(90deg, #2C4690 5%, #2D5AA2 97%); border-radius: 8px; font-size: 16px; line-height: 22px; color: #FFF; padding: 40px 40px 25px 40px; clear: both; }
.product-prise { font-size: 25px; color: #E9F100; margin-bottom: 20px; text-align: center; margin-top: 0; }
.product-prise ins { text-decoration: none; }
.woocommerce div.product .product-left form.cart { padding-left: 15px; margin-bottom: 45px; }
.woocommerce div.product .product-left form.cart div.quantity input { background: transparent; margin: 0; color: #FFF; border: 2px solid #ECF2F9; border-radius: 12px 0 0 12px;padding: 0; height: 50px; width: 135px; text-align: left; padding-left: 15px; -webkit-appearance: textfield;  -moz-appearance: textfield;  appearance: textfield; }
.woocommerce div.product .product-left form.cart .button { padding: 0 25px; text-align: center; line-height: 42px; height: 50px; background: #e9f100; border: 2px solid #e9f100; color: #2C4690; font-weight: 700; border-radius: 0 100px 100px 0; font-family: museo-sans-condensed, sans-serif; font-size: 20px; }
.woocommerce div.product .product-left form.cart .button:before { display: inline-block; font: normal normal normal 14px/1 FontAwesome; font-size: inherit; text-rendering: auto; -webkit-font-smoothing: antialiased; margin-right: 5px; }
.woocommerce div.product form.cart div.quantity { margin-right: 0; }
.product-cart p { margin-bottom: 20px; }
.woocommerce div.product .out-of-stock { color: #e9f100; text-align: center; }
.contact-info { text-align: center; font-weight: 700; font-size: 20px; line-height: 26px; padding: 30px 50px; max-width: 465px; margin: 0 auto; }

.product-info { float: left; width: 75%; }
.product-single-name { font-size: 34px; color: #2C4690; font-weight: 700; margin-bottom: 5px; text-transform: uppercase; font-family: museo-sans-condensed, sans-serif; }
.ref-no { font-size: 26px; color: #4A4A4A; font-weight: 700; line-height: 26px; display: block; font-family: museo-sans-condensed, sans-serif; }
.single-image { margin-top: 30px; }
.product-info p { margin-top: 20px; }
.product-info ul { margin: 30px 0; padding: 0 0 0 30px;}
.product-info ul li { padding-left: 5px; padding-bottom: 10px; }
.signature-image { float: right; }

.product-descreption { overflow-x: hidden; padding: 15px 0; }
.line-title { position: relative; font-size: 25px; color: #2C4690; font-weight: 700; margin-bottom: 30px; margin-top: 0; }
.line-title:after { content: ""; display: inline-block; height: 1px; width: 100%; background: #dce2f2; position: absolute; margin-left: 15px; top: 15px; }
.product-descreption strong { display: block; padding-bottom: 10px; }

/*---------------- SINGLE PRODUCT CSS ---------------------*/
.single-product .container h3 { position: relative; font-size: 25px; color: #2C4690; font-weight: 700; margin-bottom: 30px; margin-top: 0; }
.single-product .container h3::after { content: "\0020"; display: inline-block; height: 1px; width: 100%; background: #dce2f2; position: absolute; margin-left: 15px; top: 15px; }
.single-product .product-cart h2, .single-product .product-cart h3, .single-product .product-cart h4, .single-product .product-cart h5 { color: #ffffff; }
.single-product .product-cart h3::after { display: none; }


/*---------------- FORM CSS ---------------------*/
.entry-content input, .entry-content textarea { text-indent: 5px; }
.entry-content input, .entry-content input:hover, .entry-content input:focus, .entry-content textarea, .entry-content textarea:focus { border: 1px solid #4A4A4A; outline: none; }
.entry-content input:hover, .entry-content input:focus, .entry-content textarea:hover, .entry-content textarea:focus { background: darken(#ffffff, 5%); }
.entry-content .label-above .nf-field-label { margin-bottom: 0; }
.nf-after-field .nf-error-msg { padding-top: -10px; }
.ninja-forms-field[type="checkbox"] { min-width: 15px; min-height: 15px; }
.ninja-forms-field[type="checkbox"].nf-checked { background: url(/wp-content/themes/blue-chip/assets/media/icon-check.svg); }
.ninja-forms-field[type="button"] { background: #2c4690; color: #ffffff; margin: 0; padding: 5px 10px; text-indent: 0; border: none; border-radius: 3px; }
.ninja-forms-field[type="button"]:hover { cursor: pointer; }
.ninja-forms-field[type="button"]:hover, .ninja-forms-field[type="button"]:active { background: lighten(#2c4690, 10%); color: #ffffff; border: none; }

.site-footer {
    .contact-form .nf-error-msg, .ninja-forms-req-symbol { color: #ec7f7f; }
    .contact-form .nf-error .ninja-forms-field { border: 1px solid #ec7f7f; }
    .contact-form .nf-before-form-content { display: none; }
    .contact-form .nf-form-content nf-fields-wrap { display: flex; }
    .contact-form .nf-form-content nf-field { flex: 1 1 0%; }
    .contact-form .nf-form-content nf-field .nf-field-container, .contact-form .nf-form-content .nf-field { width: 100%; margin-bottom: 12px; }
    .contact-form .nf-form-content nf-field { padding-right: 20px; }
    .contact-form .nf-form-content nf-field:last-of-type { flex: 0 1 0%; }
    .contact-form .-no_label label { display: none; }
    .contact-form input, .contact-form input[type="button"] { height: 48px; }
    .contact-form input[type="button"] { border: 2px solid #e9f100; border-radius: 24px; width: auto; padding: 0 48px; background: #e9f100; color: #2c4690; font-weight: 700; font-size: 20px; font-family: museo-sans-condensed, sans-serif }
    .contact-form input[type="button"]:hover { background: none; color: #e9f100; }
}

/*---------------- 404 PAGE ---------------------*/
.error-404 { padding: 100px 0; text-align: center; }
.error-404 a.button { border: 1px solid #456379; display: inline-block; padding: 5px 20px; }

div.qty { float: left; padding: 10px; font-size: 20px; font-family: museo-sans-condensed, sans-serif; font-weight: 300; line-height: 28px;}
.flex-viewport { cursor: pointer; }
